import React, { useState, useEffect, useRef } from 'react';
import { realtimeDb, auth } from '../firebaseConfig';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { ref, onValue, push, set, off } from 'firebase/database';
import { Modal, Button, Input, List, Divider, Badge, Upload } from 'antd';
import { UploadOutlined, CloseCircleOutlined, FileOutlined } from '@ant-design/icons';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Pomoc.css';
import { SmileOutlined, AudioOutlined, SendOutlined } from '@ant-design/icons';

const Pomoc = () => {
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState({});
    const [chatName, setChatName] = useState('');
    const [newChatFirstMessage, setNewChatFirstMessage] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCreateChatModalVisible, setIsCreateChatModalVisible] = useState(false);
    const [unreadCounts, setUnreadCounts] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState(null);

    const messagesEndRef = useRef(null);
    const firestore = getFirestore();
    const storage = getStorage();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (usr) => {
            if (usr) {
                setUser(usr);
                const userDocRef = doc(firestore, 'users', usr.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    setUserInfo(userDocSnap.data());
                }
            }
        });
        return () => {
            unsubscribe();
        };
    }, [firestore]);

    useEffect(() => {
        if (user) {
            const chatsRef = ref(realtimeDb, 'chats');
            onValue(chatsRef, (snapshot) => {
                const allChats = [];
                snapshot.forEach((snap) => {
                    const chatData = { id: snap.key, ...snap.val() };
                    // Показываем только чаты, созданные этим пользователем
                    if (chatData.creatorId === user.uid) {
                        allChats.push(chatData);
                    }
                });

                // Для каждого чата считаем непрочитанные
                const promises = allChats.map(async (c) => {
                    const messagesRef = ref(realtimeDb, `chats/${c.id}/messages`);
                    const msgs = await new Promise((resolve) => {
                        onValue(messagesRef, (snap) => {
                            const arr = [];
                            snap.forEach((child) => {
                                arr.push({ id: child.key, ...child.val() });
                            });
                            resolve(arr);
                        });
                    });

                    c.messages = msgs;
                    c.unreadCount = countUnreadMessagesInChat(msgs);
                    return c;
                });

                Promise.all(promises).then((results) => {
                    // Обновляем глобальный счётчик непрочитанных
                    const unreadObj = {};
                    results.forEach((r) => {
                        unreadObj[r.id] = r.unreadCount;
                    });
                    setUnreadCounts(unreadObj);

                    // Сортируем: сначала по количеству непрочитанных, затем по времени
                    const sorted = results.sort((a, b) => {
                        if (b.unreadCount !== a.unreadCount) {
                            return b.unreadCount - a.unreadCount;
                        }
                        const bTime = new Date(b.lastMessage?.timestamp || 0).getTime();
                        const aTime = new Date(a.lastMessage?.timestamp || 0).getTime();
                        return bTime - aTime;
                    });

                    setChats(sorted);
                });
            });
        }
    }, [user]);

    const countUnreadMessagesInChat = (msgs) => {
        if (!user) return 0;
        return msgs.reduce((count, m) => {
            // Ваше условие: сообщение ещё не прочитано и отправитель не текущий пользователь
            if (!m.isRead && m.senderId !== user.uid) {
                return count + 1;
            }
            return count;
        }, 0);
    };

    // При выборе чата грузим сообщения в реальном времени
    useEffect(() => {
        let messagesRef;
        if (selectedChat) {
            messagesRef = ref(realtimeDb, `chats/${selectedChat.id}/messages`);
            onValue(messagesRef, (snap) => {
                const msgsArr = [];
                snap.forEach((child) => {
                    msgsArr.push({ id: child.key, ...child.val() });
                });
                setMessages(msgsArr);

                // Проставляем isRead = true, если сообщение отправлено другим пользователем
                msgsArr.forEach((m) => {
                    if (!m.isRead && m.senderId !== user?.uid) {
                        set(ref(realtimeDb, `chats/${selectedChat.id}/messages/${m.id}/isRead`), true);
                        set(ref(realtimeDb, `chats/${selectedChat.id}/messages/${m.id}/readAt`), new Date().toISOString());
                    }
                });
                scrollToBottom();
            });
        }

        return () => {
            if (messagesRef) off(messagesRef);
        };
    }, [selectedChat, user]);

    // Скроллим вниз при появлении новых сообщений
    useEffect(() => {
        if (messages.length > 0) {
            scrollToBottom();
        }
    }, [messages]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            setTimeout(() => {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    };

    // Создание чата + первое сообщение
    const createChat = async () => {
        if (!chatName.trim() || !newChatFirstMessage.trim()) return;

        const newChatRef = push(ref(realtimeDb, 'chats'));
        const newChatKey = newChatRef.key;
        const now = new Date().toISOString();
        const creatorName = `${userInfo.firstName || ''} ${userInfo.lastName || ''}`.trim() || user.email;

        // Создаём запись чата
        await set(newChatRef, {
            chatName,
            creatorId: user.uid,
            creatorName,
            timestamp: now,
            status: 'open',
            lastMessage: {
                text: '',
                senderName: '',
                timestamp: '',
                senderRole: 'user',
            },
            admins: [],
        });

        // Отправляем первое сообщение
        const firstMsgData = {
            text: newChatFirstMessage,
            fileUrl: null,
            fileType: null,
            sender: creatorName,
            senderId: user.uid,
            senderRole: 'user',
            timestamp: now,
            isRead: false,
            readAt: null,
        };

        await push(ref(realtimeDb, `chats/${newChatKey}/messages`), firstMsgData);
        await set(ref(realtimeDb, `chats/${newChatKey}/lastMessage`), {
            text: newChatFirstMessage,
            fileUrl: null,
            fileType: null,
            senderName: creatorName,
            senderRole: 'user',
            timestamp: now,
            isRead: false,
        });

        setChatName('');
        setNewChatFirstMessage('');
        setIsCreateChatModalVisible(false);

        // Автоматически открываем только что созданный чат
        setSelectedChat({
            id: newChatKey,
            chatName,
            status: 'open',
            creatorId: user.uid,
            admins: [],
        });
        setIsModalVisible(true);
    };

    const openChat = (chat) => {
        setSelectedChat(chat);
        setIsModalVisible(true);
    };

    // Отправка нового сообщения
    const sendMessage = async () => {
        if ((!message.trim() && !selectedFile) || !selectedChat) return;

        let fileUrl = null;
        let fileType = null;

        // Если есть прикреплённый файл, загружаем в Firebase Storage
        if (selectedFile) {
            const filePath = `chatFiles/${selectedChat.id}/${Date.now()}_${selectedFile.name}`;
            const fileRef = storageRef(storage, filePath);
            await uploadBytes(fileRef, selectedFile);
            fileUrl = await getDownloadURL(fileRef);
            fileType = selectedFile.type;
        }

        // Отправитель
        const senderDocRef = doc(firestore, 'users', user.uid);
        const senderDocSnap = await getDoc(senderDocRef);
        const senderName = senderDocSnap.exists()
            ? `${senderDocSnap.data().firstName} ${senderDocSnap.data().lastName}`
            : user.email;

        const now = new Date().toISOString();
        const msgData = {
            text: message,
            fileUrl: fileUrl || null,
            fileType: fileType || null,
            sender: senderName,
            senderId: user.uid,
            senderRole: 'user',
            timestamp: now,
            isRead: false,
            readAt: null,
        };

        await push(ref(realtimeDb, `chats/${selectedChat.id}/messages`), msgData);
        await set(ref(realtimeDb, `chats/${selectedChat.id}/lastMessage`), {
            text: message,
            fileUrl: fileUrl || null,
            fileType: fileType || null,
            senderName,
            senderRole: 'user',
            timestamp: now,
            isRead: false,
        });

        setMessage('');
        setSelectedFile(null);
        setPreviewImageUrl(null);
        scrollToBottom();
    };

    const handleFileChange = (info) => {
        const file = info.file.originFileObj || info.file;
        if (file instanceof Blob) {
            setSelectedFile(file);
            if (file.type.startsWith('image/')) {
                const previewUrl = URL.createObjectURL(file);
                setPreviewImageUrl(previewUrl);
            } else {
                setPreviewImageUrl(null);
            }
        } else {
            console.error('The selected file is not a valid Blob or File object');
        }
    };

    const removeSelectedImage = () => {
        setSelectedFile(null);
        setPreviewImageUrl(null);
    };

    const openImageModal = (imageUrl) => {
        setModalImageUrl(imageUrl);
        setIsImageModalVisible(true);
    };

    const closeImageModal = () => {
        setModalImageUrl(null);
        setIsImageModalVisible(false);
    };

    const extractFileName = (url) => {
        try {
            const fullPath = decodeURIComponent(url.split('?')[0]);
            return fullPath.substring(fullPath.lastIndexOf('/') + 1);
        } catch (error) {
            return 'File';
        }
    };

    const truncateFileName = (fileName, maxLength = 20) => {
        if (fileName.length <= maxLength) return fileName;
        const extIndex = fileName.lastIndexOf('.');
        const extension = extIndex > -1 ? fileName.slice(extIndex) : '';
        const baseName = extIndex > -1 ? fileName.slice(0, extIndex) : fileName;
        return `${baseName.slice(0, maxLength - extension.length - 3)}...${extension}`;
    };

    const formatMessages = () => {
        const formatted = [];
        let lastDate = '';

        messages.forEach((msg) => {
            const messageDate = new Intl.DateTimeFormat('pl-PL', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }).format(new Date(msg.timestamp));

            // Если новая дата — вставляем разделитель
            if (messageDate !== lastDate) {
                formatted.push(
                    <div key={`sep-${messageDate}`} className="date-separator">
                        {messageDate}
                    </div>
                );
                lastDate = messageDate;
            }

            // Свои или чужие (для стилизации)
            const isOwn = msg.senderId === user?.uid;
            const bubbleClass = isOwn ? 'you' : 'other';

            formatted.push(
                <div key={msg.id || msg.timestamp} className={`message-item ${bubbleClass}`}>


                    {/* Основной пузырёк */}
                    <div className="message-bubble">
                        {/* Имя отправителя + время */}
                        <div className="message-meta">
                            <span className="message-name">{isOwn ? 'Ty' : msg.sender}</span>
                            <span className="message-time">
                                {new Intl.DateTimeFormat('pl-PL', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                }).format(new Date(msg.timestamp))}
                            </span>
                        </div>

                        {/* Текст или файл */}
                        {msg.text && <div>{msg.text}</div>}
                        {msg.fileUrl && msg.fileType?.startsWith('image/') && (
                            <img
                                src={msg.fileUrl}
                                alt="Zdjęcie"
                                style={{ maxWidth: '100%', cursor: 'pointer', marginTop: '0.5rem' }}
                                onClick={() => openImageModal(msg.fileUrl)}
                            />
                        )}
                        {msg.fileUrl && msg.fileType && !msg.fileType.startsWith('image/') && (
                            <div style={{ marginTop: '0.5rem' }}>
                                <FileOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
                                <a href={msg.fileUrl} download>
                                    {truncateFileName(extractFileName(msg.fileUrl))}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            );
        });

        // «Якорь», чтобы прокрутиться в конец
        formatted.push(<div key="end" ref={messagesEndRef} />);
        return formatted;
    };


    const renderChatHeader = () => {
        if (!selectedChat) return null;

        const admin = selectedChat.admins && selectedChat.admins.length > 0 ? selectedChat.admins[0] : null;
        return (
            <div>
                <h2 style={{ margin: '0 0 8px 0' }}>{selectedChat.chatName}</h2>
                <div style={{ color: 'gray', fontSize: '14px' }}>
                    {admin ? (
                        <div>Rozmowa z: {admin.firstName} {admin.lastName}</div>
                    ) : (
                        <div>Oczekiwanie na dołączenie obsługi</div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="chat-container">
            <div className="chat-list">
                <div className="chat-header">
                    <h2>Pomoc</h2>
                    <Button type="primary" onClick={() => setIsCreateChatModalVisible(true)}>
                        Utwórz nowe zgłoszenie
                    </Button>
                </div>
                <Divider orientation="left" plain>
                    Ostatnie rozmowy
                </Divider>
                <List
                    itemLayout="horizontal"
                    dataSource={chats}
                    renderItem={(chat) => {
                        const isUnread = unreadCounts[chat.id] > 0;
                        const textStyle = { fontWeight: isUnread ? 'bold' : 'normal' };

                        return (
                            <List.Item onClick={() => openChat(chat)}>
                                <List.Item.Meta
                                    title={
                                        <div>
                                            <Badge
                                                count={unreadCounts[chat.id] || 0}
                                                overflowCount={99}
                                                offset={[15, 5]}
                                                style={{ backgroundColor: '#ff4d4f', color: '#fff', boxShadow: 'none' }}
                                            >
                                                <span style={textStyle}>{chat.chatName}</span>
                                            </Badge>
                                        </div>
                                    }
                                    description={
                                        <>
                                            <div style={textStyle}>Status: {chat.status}</div>
                                            <div style={textStyle}>
                                                Utworzono: {new Date(chat.timestamp).toLocaleString('pl-PL')}
                                            </div>
                                            {chat.lastMessage && chat.lastMessage.text && (
                                                <div style={textStyle}>
                                                    Ostatnia wiadomość: {new Date(chat.lastMessage.timestamp).toLocaleString('pl-PL')}
                                                </div>
                                            )}
                                        </>
                                    }
                                />
                            </List.Item>
                        );
                    }}
                />
            </div>

            <Modal
                title="Utwórz nowy czat"
                visible={isCreateChatModalVisible}
                onCancel={() => setIsCreateChatModalVisible(false)}
                onOk={createChat}
                okText="Utwórz"
                cancelText="Anuluj"
            >
                <Input
                    placeholder="Nazwa nowego czatu (temat)"
                    value={chatName}
                    onChange={(e) => setChatName(e.target.value)}
                    style={{ marginBottom: '1rem' }}
                />
                <Input.TextArea
                    placeholder="Pierwsza wiadomość"
                    value={newChatFirstMessage}
                    onChange={(e) => setNewChatFirstMessage(e.target.value)}
                    rows={4}
                />
            </Modal>

            <Modal
                title={renderChatHeader()}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                style={{ top: 20 }}
                bodyStyle={{ padding: 0 }} /* убираем отступы для «чистого» вида */
                width="100%"
            >
                {selectedChat ? (
                    <div className="chat-window">
                        <div className="messages">{formatMessages()}</div>
                        {selectedChat.status === 'open' ? (
                            <>
                                {previewImageUrl ? (
                                    <div className="image-preview">
                                        <img
                                            src={previewImageUrl}
                                            alt="Preview"
                                            onClick={removeSelectedImage}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <CloseCircleOutlined
                                            onClick={removeSelectedImage}
                                            style={{ position: 'absolute', top: 8, right: 8 }}
                                        />
                                    </div>
                                ) : selectedFile ? (
                                    <div className="file-preview">
                                        <FileOutlined style={{ fontSize: '48px' }} />
                                        <span>{selectedFile.name}</span>
                                        <CloseCircleOutlined
                                            onClick={removeSelectedImage}
                                            style={{ position: 'absolute', top: 8, right: 8 }}
                                        />
                                    </div>
                                ) : null}

                                <div className="message-input">
                                    {/* Кнопки слева: прикрепить, emoji, voice */}


                                    {/* Поле ввода текста */}
                                    <Input
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        onPressEnter={sendMessage}
                                        placeholder="Napisz wiadomość..."
                                    />

                                    {/* Кнопка отправки */}
                                    <div className="toolbox-icons">
                                        <Upload
                                            beforeUpload={() => false}
                                            onChange={handleFileChange}
                                            showUploadList={false}
                                        >
                                            <Button icon={<UploadOutlined />} />
                                        </Upload>
                                    </div>
                                    <div className="toolbox-icons">
                                    <Button icon={<SendOutlined />} onClick={sendMessage} />
                                    </div>
                                </div>

                            </>
                        ) : (
                            <div className="chat-closed-message" style={{ padding: '1rem' }}>
                                <p>Czat zamknięty przez administratora.</p>
                                <p>Żeby napisać wiadomość, utwórz nowe zgłoszenie.</p>
                                <Button type="primary" onClick={() => setIsCreateChatModalVisible(true)}>
                                    Utwórz nowy czat
                                </Button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ padding: '1rem' }}>Proszę wybrać czat</div>
                )}
            </Modal>

            {/* Модал для просмотра полноразмерного изображения */}
            <Modal
                visible={isImageModalVisible}
                footer={null}
                onCancel={closeImageModal}
                style={{ top: 20 }}
            >
                <img src={modalImageUrl} alt="Modal Content" style={{ width: '100%' }} />
                <Button type="primary" href={modalImageUrl} download style={{ marginTop: '10px' }}>
                    Pobierz
                </Button>
            </Modal>
        </div>
    );
};

export default Pomoc;
